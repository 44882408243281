@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    html, body{
        font-family: helvetica, sans-serif;
    }
    .ltr {
        direction: ltr;
    }

    .rtl {
        direction: rtl;
    }


    .default-btn{
        @apply bg-primary text-center text-white radius-[5] py-2 px-8
    }
    .secondary-btn{
        @apply bg-secondary text-center text-white radius-[5] py-2 px-8
    }


    .header-nav{
        @apply flex-it-[center-start] w-full text-white text-sm
        max-md:flex-it-[start-start]
    }
    .header-nav .item{
        @apply px-4 block
        max-md:px-4 max-md:py-4 max-md:border-b max-md:border-gray-900 max-md:w-full
    }
    .header-nav .nav-dropdown{
        @apply absolute top-8 bg-primary max-w-[300px] w-full z-[10]
        max-md:bg-transparent max-md:relative max-md:top-0 max-md:max-w-full
    }
    .header-nav .nav-dropdown .sub-item{
        @apply text-white text-sm block px-5 py-2
        max-md:text-black
    }


    .phone-menu{
        @apply relative
    }
    .phone-menu .header-nav{
        @apply  text-black
    }
    .phone-menu .items-holder{
        @apply fixed z-[1000] bg-white inset-y-0 w-2/3 h-full transition duration-300
    }
    .phone-menu.active .items-holder{
        @apply translate-x-0
    }
    .phone-menu .over{
        @apply fixed inset-0 z-[999] m-auto bg-black bg-opacity-40 opacity-0 invisible transition duration-300
    }
    .phone-menu.active .over{
        @apply opacity-100 visible
    }


    .footer{
        @apply bg-primary py-10
    }
    .footer .links .item{
        @apply text-white text-sm block py-2
    }
    .footer .title{
        @apply text-shade-1 mb-2 font-bold
    }

    .newsletter{

    }
    .newsletter .t{
        @apply text-white mb-4
    }
    .newsletter form{
        @apply flex-it-[center-between]
    }
    .newsletter input{
        @apply text-sm px-4 py-2 border-0 bg-white bg-opacity-80 w-calc-[80px]
    }
    .newsletter button{
        @apply text-primary text-sm text-center px-2 py-2 bg-shade-1 w-[70px]
    }

    .footer .social{
        @apply flex-it-[center-start] text-white
    }

    .footer .social a{
        @apply size-5 mx-1 text-sm flex-it-[center-center]
    }

    .page-cover{
        @apply h-[400px] bg-no-repeat bg-center bg-cover flex-it-[center-center]
    }
    .page-cover .t{
        @apply font-bold text-white text-3xl text-center
    }


    .site-width{
        @apply contain-it-[95-1080]
    }

    .share-link{
        @apply flex-it-[center-center] w-full text-sm text-gray-800 py-6
    }
    .share-link a{
        @apply block mx-4 hover:text-primary transition duration-300
    }












    .programs-index{}
    .programs-index .snippet {
        @apply text-center text-sm contain-it-[95-768] py-14
    }

    .program-container{
        @apply mb-8
    }
    .program-container .ribbon{
        @apply bg-secondary px-3 pb-2 pt-3 text-white font-bold mb-2
    }
    .program-container .s{
        @apply py-2
    }
    .list{
        @apply grid grid-cols-3 gap-8
    }
    .program, .album{
        @apply bg-white cursor-pointer
    }
    .program img, .album img{
        @apply block
    }
    .program p, .album p{
        @apply font-bold text-center px-3 py-4
    }

    .program-youtube-video{
        @apply py-16
    }

    .media-files{

    }
    .media-files .file{
        @apply flex-it-[center-center] bg-gray-950 py-3
    }


    .media-videos .video{
        @apply flex-it-[end-end] bg-black py-10 px-10 min-h-[450px]
    }
    .media-videos button{
        @apply flex-it-[center-center] bg-youtube text-white rounded-full py-4 px-5
    }


    .form{

    }
    .form .input-container{
        @apply border border-gray-950 mb-4 last:mb-0
    }
    .form input, .form textarea{
        @apply py-3 px-3 text-sm w-full h-full focus:border-transparent focus:ring-0 focus:outline-none
    }
    .form textarea{
        @apply m-0 resize-none block
    }
    .form button{
        @apply secondary-btn mt-4
    }


    .faq{
        @apply border border-gray-800 border-b-0 last:border-b-[1px]
    }
    .faq button{
        @apply bg-gray-1000 py-4 px-4 w-full flex-it-[center-between]
    }
    .faq .answer{
        @apply py-4 px-4 border-t border-gray-800 text-sm text-[gray]
    }



    .floating-social{
        @apply w-[45px] h-[270px] fixed inset-y-0 m-auto flex-it-[start-between] flex-col
    }
    .floating-social a{
        @apply block w-full h-[45px] flex-it-[center-center] text-white
    }

    .animation-paused {
        animation-play-state: paused;
    }
}

@font-face {
    font-family: helvetica;
    src: url('fonts/helveticaneuew23forsky-reg.woff');
    font-weight: 400;
    font-style: normal
}
@font-face {
    font-family: fl;
    src: url('fonts/FrutigerLTArabic-45Light.woff2');
    font-weight: 400;
    font-style: normal
}
@font-face {
    font-family: fb;
    src: url('fonts/FrutigerLTArabic-75Black.woff2');
    font-weight: 700;
    font-style: normal
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0;
}
a,button{
    cursor: pointer;
}
.yarl__thumbnails_thumbnail {
    border: 1px solid #1d1d1d !important;
}

.yarl__container {
    background: #000000c2 !important;
}
